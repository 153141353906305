import React from "react";
import { Box } from "@mui/material";
import LeftBarChartComponent from "../../../graph/barChart/LeftBarChart";

const MonthlyScore = ({monthlyScore}) => {
  let xaxis = [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998];
  let data = [30, 40, 45, 50, 49, 60, 70, 91];
  xaxis = Object.keys(monthlyScore)
  data = Object.values(monthlyScore)
  return (
    <Box>
      <LeftBarChartComponent id="basic-bar" xaxis={xaxis} data={data} />
    </Box>
  );
};

export default MonthlyScore;
