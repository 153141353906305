import React, { Component } from "react";
import Chart from "react-apexcharts";

class LineChartComponent extends Component {
  render() {
    const { id, xaxis, data, width } = this.props;

    const options = {
      chart: {
        id: id,
        toolbar: { show:false },
      },
      xaxis: {
        categories: xaxis,
      },
      markers: {
        size: 5,
        colors: "#fff",
        strokeColors: "#26a0fc",
        strokeWidth: 2,
        strokeOpacity: 0.9,
        strokeDashArray: 0,
        fillOpacity: 1,
        discrete: [],
        shape: "circle",
        radius: 2,
        offsetX: 0,
        offsetY: 0,
        onClick: undefined,
        onDblClick: undefined,
        showNullDataPoints: true,
        hover: {
          size: undefined,
          sizeOffset: 3,
        },
      },
    };

    const series = [
      {
        name: "series-1",
        data: data,
      },
    ];

    return (
      <div className="mixed-chart">
        <Chart
          options={options}
          series={series}
          type="line"
          width={width ? width : 250}
        />
      </div>
    );
  }
}

export default LineChartComponent;
