import React, { Component } from "react";
import Chart from "react-apexcharts";

class LeftBarChartComponent extends Component {
  render() {
    const { id, xaxis, data } = this.props;

    const options = {
      chart: {
        id: id,
        type: 'bar',
        height: 350,
        toolbar: { show:false },
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true,
        }
      },
      dataLabels: {
        position: 'top',
      },
      xaxis: {
        categories: xaxis,
      },
      yaxis: {
        labels: {
          style: {
            fontSize: '15px', // Adjust the font size as needed
            fontWeight: 'bold', // Make the font bold
          }
        }
      },
      grid: {
        show: false, // Hide grid lines
      }
    };

    const series = [
      {
        name: "series-1",
        data: data,
      }
    ];

    return (
      <div className="mixed-chart">
        <Chart
          options={options}
          series={series}
          type="bar"
          width="500"
        />
      </div>
    );
  }
}

export default LeftBarChartComponent;
