import { Box, Typography } from '@mui/material'
import React from 'react'

const Classification = () => {
  return (
    <>
    <Box sx={{display:"flex"}}>
        <Box sx={{display:"flex"}}>
            <Box sx={{height:"25px", width:"25px", backgroundColor:"red"}}>
            </Box>
            <Typography sx={{marginLeft:"1rem"}}>
                Bottom 25%
            </Typography>
        </Box>
        <Box sx={{display:"flex", marginLeft:"1rem"}}>
            <Box sx={{height:"25px", width:"25px", backgroundColor:"#fff200"}}>
            </Box>
            <Typography sx={{marginLeft:"1rem"}}>
                Middle 25%
            </Typography>
        </Box>
        <Box sx={{display:"flex", marginLeft:"1rem"}}>
            <Box sx={{height:"25px", width:"25px", backgroundColor:"green"}}>
            </Box>
            <Typography sx={{marginLeft:"1rem"}}>
                Top 25%
            </Typography>
        </Box>
        <Box sx={{display:"flex", marginLeft:"1rem"}}>
            <Box sx={{height:"25px", width:"25px", backgroundColor:"grey"}}>
            </Box>
            <Typography sx={{marginLeft:"1rem"}}>
                Unclassified
            </Typography>
        </Box>
    </Box>
    </>
  )
}

export default Classification