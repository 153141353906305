import { Box, Typography } from "@mui/material";
import React from "react";
import MonthlyResult from "../analyticsGraphs/monthlyResult";
import MonthlyScore from "../analyticsGraphs/monthlyScore";
import BasicModal from "../../modal";

const Analytics = ({analytics}) => {
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);
  return (
    <>
      <Box>
        <Box>
          <Typography variant="caption" sx={{ fontWeight: "600" }} gutterBottom>
            {analytics.topic}
          </Typography>
          <Typography
            variant="caption"
            sx={{ marginLeft: "1rem" }}
            gutterBottom
          >
            {analytics.date}
          </Typography>
        </Box>
        <Typography variant="h4" sx={{ fontWeight: "600" }} gutterBottom>
        {analytics.title}
        </Typography>
        <Box>
          <Box sx={{display: "flex", justifyContent:"space-between", margin:"2rem 0"}}>
            <MonthlyScore monthlyScore={analytics.monthlyScore}/>
            <MonthlyResult monthlyResult={analytics.monthlyResult}/>
          </Box>

        <Typography sx={{ fontWeight: "600" }} >
        {analytics.feedback[0]}
        </Typography>
        <Typography sx={{cursor: 'pointer',}} onClick={(e)=>{ setOpen(true); e.preventDefault();}} variant="subtitle1" >
        {analytics.feedback[1]}
        </Typography>
        </Box>
      </Box>
      {open && (<BasicModal props={open} handleClose ={handleClose} handleOpen={handleOpen}/>)}
    </>
  );
};

export default Analytics;
