import React from "react";
import graph from "../../../../assets/img/analyticsForResponses/monthly-result.png";
import { Box, Typography } from "@mui/material";
import LineChartComponent from "../../../graph/lineChart";

const MonthlyResult = ({monthlyResult}) => {
  let xaxis = [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998];
  let data = [30, 40, 45, 50, 49, 60, 70, 91];
  xaxis = Object.keys(monthlyResult.data);
  data = Object.values(monthlyResult.data)
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex" }}>
          <Typography sx={{ fontWeight: "600" }}>{monthlyResult.result}</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
          }}
        >
          <Box
            sx={{
              width: "40px",
              padding: "0.2rem 1.5rem",
              backgroundColor: "green",
              color: "white",
            }}
          >
            <Typography variant="body2" gutterBottom>
            {monthlyResult.rating}
            </Typography>
          </Box>
          <Box sx={{ marginLeft: "1rem" }}>
            <Box
              sx={{
                width: "80px",
                padding: "0.2rem 1.5rem",
                border: "1px solid black",
                textAlign: "center",
              }}
            >
              <Typography variant="body2" gutterBottom>
              {monthlyResult.score}
              </Typography>
            </Box>
            <Typography variant="caption" gutterBottom>
            {monthlyResult.meanScore}
            </Typography>
          </Box>
        </Box>
      </Box>
      <LineChartComponent id="basic-bar" xaxis={xaxis} data={data} width={400} />
    </Box>
  );
};

export default MonthlyResult;
