import React, { Component } from "react";
import Chart from "react-apexcharts";

class BarChartComponent extends Component {
  render() {
    const { id, xaxis, data, width } = this.props;
    const customColors = data.map((value) => {
      if (value >= 0 && value <= 25) {
        return "#D9534F"; // Red color for values between 0 and 25
      } else if (value > 25 && value <= 75) {
        return "#7E36AF"; // Purple color for values between 25 and 75
      } else {
        return "#5BC0DE"; // Blue color for values greater than 75
      }
    });

    const options = {
      // colors: ['#546E7A', '#E91E63', '#546E7A', '#fffff'], 
      chart: {
        toolbar: { show:false },
        id: id
      },
      xaxis: {
        categories: xaxis,
        labels: {
          show: false // Hide x-axis labels
        },
      },
      yaxis: {
        labels: {
          show: false // Hide y-axis labels
        },
      },
    colors: [ // this array contains different color code for each data
        "#ff0000",
        "#ff6c6c",
        "#fff200",
        "#62ff62",
        "#008000",
    ],
    legend: { show: false, },
    bar: {
      dataLabels: {
        position: 'top'
      }
    },
    dataLabels: {
      enabled: true,
      style: {
          colors: ['#333']
      },
      offsetY: -12
    },
    grid: {
      show: false, // Hide grid lines
    },
    plotOptions: {
      bar: {
        distributed: true,
        dataLabels: {
          position: 'top', // top, center, bottom
        },
        columnWidth : '35%',
      }
    },
    };

    const series = [
      {
        name: "series-1",
        data: data
      }
    ];

    return (
      <div className="mixed-chart">
        <Chart
          options={options}
          series={series}
          type="bar"
          width={width?width:500}
          // colors= {["#fffff"]}
        />
      </div>
    );
  }
}

export default BarChartComponent;
