import { Box, Button, TextField, Typography } from '@mui/material'
import React from 'react'

const ShareRecommendation = () => {
  return (
    <>
    <Typography variant="h4" gutterBottom>
    Share Recommendation
      </Typography>
      <Typography variant="h6" gutterBottom>
      Your submissions will be published to help other messages at am learn from you
      </Typography>
      <Box sx={{backgroundColor:"#eeeeee8c", padding:"2rem", margin:"2rem 0", borderRadius:"10px"}}>
      <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            float:"right"
          }}
        >
          <Box
            sx={{
              width: "40px",
              padding: "0.1rem 1rem",
              backgroundColor: "green",
              color: "white",
            }}
          >
            <Typography variant="body2" gutterBottom>
              13% above
            </Typography>
          </Box>
          <Box sx={{ marginLeft: "1rem" }}>
            <Box
              sx={{
                width: "80px",
                padding: "0.2rem 1rem",
                border: "1px solid black",
                textAlign: "center",
              }}
            >
              <Typography variant="body2" gutterBottom>
                100% favourable
              </Typography>
            </Box>
            <Typography variant="caption" gutterBottom>
              4.5 mean score
            </Typography>
          </Box>
        </Box>
      <Typography variant="body2">
        Top 25% questions
      </Typography>
      <Typography variant="body1">
      I recieve positive recognition from my manager for my accomplishments
      </Typography>

      </Box>
      <TextField
      fullWidth 
          id="filled-multiline-flexible"
          label="Enter your recommendation"
          multiline
          rows={4}
          maxRows={4}
        //   variant="filled"
        />
        <Box  sx={{backgroundColor:"#eeeeee8c", padding:"1rem", margin:" 0 0 2rem"}}>
            <Typography>
            0 out of 500 characters entered
            </Typography>
        </Box>
        <Button
              variant="text" 
              sx={{ textTransform: "none !important"}}
            >
              View examples
            </Button>

        <Box sx={{float:"right"}}>
            <Button
              variant="text" 
              sx={{ textTransform: "none !important", marginLeft: "1rem" }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{ textTransform: "none !important", borderRadius: "25px" }}
            >
              Share Recommendation
            </Button>
          </Box>
    </>
  )
}

export default ShareRecommendation