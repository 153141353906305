import { Box, Typography } from "@mui/material";
import React from "react";
import Classification from "../components/common/classification";
import Analytics from "../components/analyticsForResponsesOfQuestion/analytics";
import Recommendation from "../components/analyticsForResponsesOfQuestion/recommendation";
import PrevNextButton from "../components/common/prevNextButton";
import { ANALYTICS_OF_RESPONSES } from "../assets/dummyData";
import BasicModal from "../components/modal";

const Questions = () => {
  return (
    <>
      <Box sx={{ margin: "4rem" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography variant="h4" sx={{fontWeight:"800"}} gutterBottom>
              Questions
            </Typography>
          </Box>
          <Box>
          <PrevNextButton />
            <Classification />
          </Box>
        </Box>
        <Box sx={{ marginTop: "3rem" }}>
            <Analytics analytics={ANALYTICS_OF_RESPONSES}/>
        </Box>
        <Box sx={{ marginTop: "3rem" }}>
          <Recommendation values={ANALYTICS_OF_RESPONSES.recommendation}/>
        </Box>
      </Box>
      <BasicModal />
    </>
  );
};

export default Questions;
