import { Box, Button } from "@mui/material";
import React from "react";
import EastIcon from "@mui/icons-material/East";
import WestIcon from "@mui/icons-material/West";

const PrevNextButton = () => {
  return (
    <>
    <Box sx={{display:"flex",justifyContent:"space-between"}}>
      <Button
        variant="text"
        sx={{ textTransform: "none !important", borderRadius: "25px" }}
      >
        <WestIcon />&nbsp;
        Previous Question
      </Button>
      <Button
        variant="text"
        sx={{ textTransform: "none !important", marginLeft: "1rem" }}
      >
        Next Question&nbsp;
        <EastIcon />
      </Button>
      </Box>
    </>
  );
};

export default PrevNextButton;
