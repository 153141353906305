import logo from './logo.svg';
import './App.css';
import QuestionTile from './components/allQuestionContainer/questionTile';
import InformationTile from './components/themes/informationTile';
import Themes from './pages/themes';
import Questions from './pages/analyticsForResponsesOfQuestion';
import AllQuestionContainer from './pages/allQuestionContainer';
import ShareRecommendation from './components/modal/shareRecommendation';
import BasicModal from './components/modal';
import LineChartContainer from './components/graph/lineChart';
import BarComponent from './components/graph/barChart';
import LineChartComponent from './components/graph/lineChart';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { Fab } from '@mui/material';
import NavigationIcon from '@mui/icons-material/Navigation';
import ForumIcon from '@mui/icons-material/Forum';

function App() {
  const navigateToExternalPage = () => {
    window.open("http://chatbot.apik.ai", "_blank");
  };
  const fabStyle = {
    position: "fixed",
    bottom: "20px", // Adjust the distance from the bottom as needed
    right: "20px",   // Adjust the distance from the left as needed
  };
  const router = createBrowserRouter([
    {
      path: "/",
      element: <AllQuestionContainer />,
    },
    {
      path: "/question",
      element: <Questions />,
    },
    {
      path: "/themes",
      element: <Themes />,
    },
  ]);
  return (
    <div className="App">
      {/* <BasicModal /> */}
      {/* <ShareRecommendation /> */}
      {/* <InformationTile /> */}
      {/* <AllQuestionContainer /> */}
      {/* <Questions /> */}
      {/* <Themes /> */}
      <div style={fabStyle} onClick={navigateToExternalPage}>
      <Fab variant="extended">
  <ForumIcon sx={{ mr: 1 }} />
  Chat
</Fab>
</div>
    </div>
  );
}

export default App;
