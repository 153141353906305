import { Box, Button, Typography } from "@mui/material";
import React from "react";
import QuestionTile from "../components/allQuestionContainer/questionTile";
import Classification from "../components/common/classification";
import PrevNextButton from "../components/common/prevNextButton";
import { ALL_QUESTIONS_CONTAINER } from "../assets/dummyData";
import {
  Link, useNavigate,
} from "react-router-dom";
import BasicModal from "../components/modal";

const AllQuestionContainer = () => {
  const navigate = useNavigate();
  return (
    <>
      <Box sx={{ margin: "4rem" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography variant="h4" sx={{fontWeight:"800", display:"contents"}} gutterBottom>
              {ALL_QUESTIONS_CONTAINER.title}
            </Typography>
            <Button
            onClick={() => {
              navigate(`/themes`);
            }}
            small
              variant="outlined"
              sx={{ textTransform: "none !important", borderRadius: "25px", color: "#006295", border:"2px solid #006295", transform:"translate(8px, -5px)" }}
            >
              Themes
            </Button>
            <Typography variant="subtitle1" gutterBottom>
            {ALL_QUESTIONS_CONTAINER.subTitle}
            </Typography>
          </Box>
          <Box>
            <Classification />
          </Box>
        </Box>
        <Box sx={{ marginTop: "2rem" }} >
        {ALL_QUESTIONS_CONTAINER.questions.map((question) => (
        <QuestionTile question={question} />
      ))}
        </Box>
      </Box>
      
    </>
  );
};

export default AllQuestionContainer;
