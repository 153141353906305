import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import ShareRecommendation from './shareRecommendation';
import Responses from './responses';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '1px solid black',
  borderRadius:"15px",
  boxShadow: 24,
  p: 4,
};

export default function BasicModal({props,handleOpen, handleClose}) {
  const [open, setOpen] = React.useState(props);
  // const handleOpen = () => setOpen(true);

  // React.useEffect(()=>{
  //   if(props){
  //     setOpen(open);
  //   }
  // },[props])

  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={open}
        onClose={()=>{(handleClose())}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <ShareRecommendation/>
          {/* <Responses/> */}
        </Box>
      </Modal>
    </div>
  );
}