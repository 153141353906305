import { Box, Typography } from "@mui/material";
import React from "react";
import QuestionTile from "../components/allQuestionContainer/questionTile";
import Classification from "../components/common/classification";
import InformationTile from "../components/themes/informationTile";
import { THEMES } from "../assets/dummyData";
const BACKGROUND_COLOR = "#f9f9f9";

const Themes = () => {
  return (
    <>
      <Box sx={{ padding: "2rem", backgroundColor:{BACKGROUND_COLOR} }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography variant="h4" sx={{fontWeight:"800"}} gutterBottom>
              Themes
            </Typography>
            {/* <Typography variant="subtitle1" gutterBottom>
              Showing 1-5 of 36 question results. Remove filters to view 8 more
              results
            </Typography> */}
          </Box>
          <Box>
            <Classification />
          </Box>
        </Box>
        <Box sx={{ marginTop: "3rem", display:"flex", flexWrap: 'wrap', justifyContent: 'space-between' }}>
          {THEMES.map((theme) => (
            <InformationTile data={theme} />
          ))}
          {/* <InformationTile />
          <InformationTile />
          <InformationTile />
          <InformationTile />
          <InformationTile /> */}
        </Box>
      </Box>
    </>
  );
};

export default Themes;
