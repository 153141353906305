import { Box, Button, Paper, Typography } from "@mui/material";
import React from "react";
import graph from "../../assets/img/allQuestionContainer/graph2.png"; // Import your image
import LineChartComponent from "../graph/lineChart";

const InformationTile = ({data}) => {
  const verdict = data.analytics.verdict.split(" ");
  const lineStyle = {
    borderTop: "1px solid grey", // Change the color and style as needed
    margin: "10px 0", // Adjust margin as needed
  };

  let xaxis = [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998];
  let dataValue = [30, 40, 45, 50, 49, 60, 70, 91];
  xaxis = Object.keys(data.analytics.data);
  dataValue = Object.values(data.analytics.data)
  return (
    <Paper elevation={3} sx={{margin:"1rem"}}>
      <Box padding={2} width={408}>
        <Typography variant="h6" gutterBottom>
          {data.title}
        </Typography>
        <Box
          sx={{
            width: "fit-content",
            padding: "0.4rem",
            backgroundColor: data.tag == "Opportunity"?"red":"green",
            color: "white",
          }}
        >
          <Typography variant="body2">{data.tag}</Typography>
        </Box>
        <div style={lineStyle}></div>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box>
            <Typography variant="h4">
              {verdict[0]}
            </Typography>
            <Typography variant="h6">favourable</Typography>
          </Box>
          <Box>
          <LineChartComponent id="basic-bar" xaxis={xaxis} data={dataValue} />
          </Box>
        </Box>
        <Box>
          <Typography sx={{ display: "contents" }} variant="body2">
            Bottom sub-themes:
          </Typography>
          <Button
            variant="text"
            sx={{ textTransform: "none !important", marginLeft: "0.2rem" }}
          >
            {data.subThemes[0]}
          </Button>
          <Button
            variant="text"
            sx={{ textTransform: "none !important", marginLeft: "0.2rem" }}
          >
            {data.subThemes[1]}
          </Button>
        </Box>
        <div style={lineStyle}></div>
        <Button
          variant="outlined"
          sx={{ textTransform: "none !important", borderRadius: "25px" }}
        >
          View Theme Details
        </Button>
      </Box>
    </Paper>
  );
};

export default InformationTile;
