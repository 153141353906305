import { Avatar, Box, Button, Typography } from "@mui/material";
import React from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";

const RecommendationTile = ({value}) => {
  const [alignment, setAlignment] = React.useState("left");
  const lineStyle = {
    borderTop: "1px solid #e2e2e2", // Change the color and style as needed
    margin: "20px 0", // Adjust margin as needed
  };

  const handleAlignment = (event, newAlignment) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
    }
  };
  return (
    <>
      <Box sx={{marginBottom:"1rem"}}>
        <Box sx={{display: "flex", alignItems:"flex-start", justifyContent: "space-between"}}>
            <Box sx={{display: "flex",}}>
          <Avatar src={value.imgUrl}/>
          <Box sx={{marginLeft:"1rem"}}>
            <Typography variant="subtitle1" sx={{ fontWeight: "600" }}>
               {value.designation}, {value.name}
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
            {value.submittedDate}
            </Typography>
          </Box>
          </Box>
          <Box sx={{display: "flex", marginLeft:"1rem"}}>
            <Button
              variant="text"
              sx={{
                textTransform: "none !important",
                borderRadius: "25px",
                color: "black",
              }}
            >
              Was this helpful?
            </Button>
            <ToggleButtonGroup
            size="small"
              value={alignment}
              exclusive
              onChange={handleAlignment}
              aria-label="text alignment"
            >
              <ToggleButton value="left" aria-label="left aligned">
                <ThumbUpIcon />
              </ToggleButton>
              <ToggleButton value="right" aria-label="right aligned">
                <ThumbDownIcon />
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
        </Box>
        <Typography>
        {value.message}        </Typography>
      </Box>
      <div style={lineStyle}></div>
    </>
  );
};

export default RecommendationTile;
