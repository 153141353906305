import { Box, Typography } from '@mui/material'
import React from 'react'
import RecommendationTile from './recommendationTile'

const Recommendation = ({values}) => {
  // console.log(values)
    const lineStyle = {
        borderTop: "1px solid #e2e2e2", // Change the color and style as needed
        margin: "20px 0", // Adjust margin as needed
      };
  return (
    <>
    <Box sx={{ marginTop: "2rem", border: "1px solid #e2e2e2", padding: "1rem" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography variant="h6" sx={{fontWeight: "600"}}>
              Recommendations
            </Typography>
            <Typography variant="subtitle1">
              Displaying 1 - 3 of 10 Recommendation
            </Typography>
          </Box>
        </Box>
        <div style={lineStyle}></div>
        <Box sx={{ marginTop: "1rem" }}>
          {values.map((value)=>
            (<RecommendationTile value={value}/>)
          )}
        </Box>
      </Box>
    </>
  )
}

export default Recommendation