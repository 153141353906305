import { Box, Button, Typography } from "@mui/material";
import React from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import graph from "../../../assets/img/allQuestionContainer/graph.png"; // Import your image
import CreateIcon from "@mui/icons-material/Create";
import BarChartComponent from "../../graph/barChart";
import BasicModal from "../../modal";
import { useNavigate } from "react-router-dom";

const QuestionTile = ({question}) => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const numericRating = parseInt(question.rating, 10);
  let xaxis = [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998];
  let data = [30, 40, 45, 50, 49, 60, 70, 91];
  xaxis = Object.keys(question.data)
  data = Object.values(question.data)
  const lineStyle = {
    borderTop: "1px solid #e2e2e2", // Change the color and style as needed
    margin: "5px 0", // Adjust margin as needed
  };

  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);
  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{width:"52%" }}>
          <Box sx={{ display: "flex", alignItems: "center", cursor: 'pointer', }} onClick={(e) => {
          e.preventDefault();
            navigate(`/question`);
          }}>
            <Typography
              variant="caption"
              sx={{ fontWeight: "600" }}
              gutterBottom
            >
              {question.topic}
            </Typography>
            <Typography
              variant="caption"
              sx={{ marginLeft: "1rem" }}
              gutterBottom
            >
              {question.dateRange}
            </Typography>
          </Box>
          <Typography variant="h5" sx={{ fontWeight: "600", cursor: 'pointer', }} gutterBottom onClick={(e) => {
          e.preventDefault();
            navigate(`/question`);
          }}>
          {question.title}
          </Typography>
          <Box>
            <Button
              variant="outlined"
              sx={{ textTransform: "none !important", borderRadius: "25px", color: "#006295", border:"2px solid #006295", fontWeight:"600" }}
            >
              View Question
            </Button>
            <Button
            onClick={(e)=>{ setOpen(true); e.preventDefault();}}
              variant="text"
              sx={{ textTransform: "none !important", marginLeft: "1rem",color: "#006295", fontWeight:"600", zIndex:"10" }}
            >
              <CreateIcon />
              Share Your Wisdom
            </Button>
          </Box>
        </Box>
        <Box sx={{cursor: 'pointer',}} onClick={(e) => {
            navigate(`/question`);
          }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
            }}
          >
            <Box
              sx={{
                width: "40px",
                padding: "0.2rem 1.5rem",
                backgroundColor: numericRating >= 0 && numericRating <= 25
                ? "red"
                : numericRating > 25 && numericRating <= 75
                ? "#fff200"
                : numericRating > 75 && numericRating <= 100
                ? "#1E8128"
                : "gray",
                color: "white",
                textAlign: "center",
              }}
            >
              <Typography variant="body2" gutterBottom>
              {question.rating}
              </Typography>
            </Box>
            <Box sx={{ marginLeft: "1rem" }}>
              <Box
                sx={{
                  width: "80px",
                  padding: "0.2rem 1.5rem",
                  border: "1px solid black",
                  textAlign: "center",
                }}
              >
                <Typography variant="body2" gutterBottom>
                {question.score}
                </Typography>
              </Box>
              <Typography variant="caption" gutterBottom>
              {question.meanScore}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box>
        <BarChartComponent id="basic-bar" xaxis={xaxis} data={data} width={200} />
          {/* <img src={graph} alt="graph" /> */}
          <Box sx={{ marginLeft: "1rem", transform: 'translate(15px, -15px)' }}>
          <Typography variant="caption" gutterBottom>
              {question.responsesCount}
              </Typography>
              <Typography variant="caption" gutterBottom sx={{ marginLeft: "1rem" }}>
              {question.rna}
              </Typography>
            </Box>
        </Box>
        <Box>
          <MoreVertIcon />
        </Box>
      </Box>
      <div style={lineStyle}></div>
      {open && (<BasicModal props={open} handleClose ={handleClose} handleOpen={handleOpen}/>)}
    </>
  );
};

export default QuestionTile;
