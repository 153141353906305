export const ALL_QUESTIONS_CONTAINER = {
  title: "Questions",
  subTitle:
    "Showing 1-36 of 36 question results. Remove filters to view 8 more results",
  questions: [
    {
      topic: "MANAGER SATISFACTION",
      dateRange: "Fri, Aug 18 - Thu, Aug 24",
      title:
        "I receive positive recognition from my manager for my accomplishments.",
      rating: "76% Above",
      score: "100% favourable",
      meanScore: "4.5 mean score",
      data:{1:"0%", 2:"0%", 3:"0%", 4:"50%", 5:"50%"},
      responsesCount:"4 responses",
      rna:"0% RNA (0)",
      responses: [
        {
          responseId: "#1",
          response:
            "The employee rates Spyne.ai at 3 for recommendation due to work-life balance concerns caused by late-night calls. Suggest addressing this issue...",
        },
        {
          responseId: "#2",
          response:
            "The employee rates Spyne.ai at 3 for recommendation due to work-life balance concerns caused by late-night calls. Suggest addressing this issue...",
        },
        {
          responseId: "#3",
          response:
            "The employee rates Spyne.ai at 3 for recommendation due to work-life balance concerns caused by late-night calls. Suggest addressing this issue...",
        },
      ],
    },
    {
      topic: "MANAGER SATISFACTION",
      dateRange: "Fri, April 1 - Thu, Aug 24",
      title:
        "My team creates a comfortable environment to express my authentic self",
      rating: "79% Above",
      score: "60% favourable",
      meanScore: "4.5 mean score",
      data:{1:"25%", 2:"25%", 3:"25%", 4:"50%", 5:"50%"},
      responsesCount:"4 responses",
      rna:"0% RNA (0)",
      responses: [
        {
          responseId: "#1",
          response:
            "The employee rates Spyne.ai at 3 for recommendation due to work-life balance concerns caused by late-night calls. Suggest addressing this issue...",
        },
        {
          responseId: "#2",
          response:
            "The employee rates Spyne.ai at 3 for recommendation due to work-life balance concerns caused by late-night calls. Suggest addressing this issue...",
        },
        {
          responseId: "#3",
          response:
            "The employee rates Spyne.ai at 3 for recommendation due to work-life balance concerns caused by late-night calls. Suggest addressing this issue...",
        },
      ],
    },
    {
      topic: "MANAGER SUPPORT",
      dateRange: "Fri, Aug 18 - Thu, Aug 24",
      title:
      "I receive recognition of my accomplishments at work ",
      rating: "95% Above",
      score: "90% favourable",
      meanScore: "4 mean score",
      data:{jan:"60%", feb:"70%", march:"80%", april:"50%", may:"50%"},
      responsesCount:"4 responses",
      rna:"0% RNA (0)",
      responses: [
        {
          responseId: "#1",
          response:
            "The employee rates Spyne.ai at 3 for recommendation due to work-life balance concerns caused by late-night calls. Suggest addressing this issue...",
        },
        {
          responseId: "#2",
          response:
            "The employee rates Spyne.ai at 3 for recommendation due to work-life balance concerns caused by late-night calls. Suggest addressing this issue...",
        },
        {
          responseId: "#3",
          response:
            "The employee rates Spyne.ai at 3 for recommendation due to work-life balance concerns caused by late-night calls. Suggest addressing this issue...",
        },
      ],
    },
    {
      topic: "CAREER DEVELOPMENT",
      dateRange: "Fri, Aug 18 - Thu, Aug 24",
      title:
        "I am making progress towards my current role",
      rating: "95% Above",
      score: "100% favourable",
      data:{jan:"20%", feb:"10%", march:"0%", april:"50%", may:"50%"},
      meanScore: "5 mean score",
      responsesCount:"4 responses",
      rna:"0% RNA (0)",
      responses: [
        {
          responseId: "#1",
          response:
            "The employee rates Spyne.ai at 3 for recommendation due to work-life balance concerns caused by late-night calls. Suggest addressing this issue...",
        },
        {
          responseId: "#2",
          response:
            "The employee rates Spyne.ai at 3 for recommendation due to work-life balance concerns caused by late-night calls. Suggest addressing this issue...",
        },
        {
          responseId: "#3",
          response:
            "The employee rates Spyne.ai at 3 for recommendation due to work-life balance concerns caused by late-night calls. Suggest addressing this issue...",
        },
      ],
    },
    {
        topic: "INCLUSIVE CULTURE",
        dateRange: "Fri, Aug 18 - Thu, Aug 24",
        title:
          "My team considers diverse perspectives into our decisions",
        rating: "13% Above",
        score: "100% favourable",
        data:{jan:"30%", feb:"20%", march:"10%", april:"50%", may:"50%"},
        meanScore: "4.5 mean score",
        responsesCount:"4 responses",
      rna:"0% RNA (0)",
        responses: [
          {
            responseId: "#1",
            response:
              "The employee rates Spyne.ai at 3 for recommendation due to work-life balance concerns caused by late-night calls. Suggest addressing this issue...",
          },
          {
            responseId: "#2",
            response:
              "The employee rates Spyne.ai at 3 for recommendation due to work-life balance concerns caused by late-night calls. Suggest addressing this issue...",
          },
          {
            responseId: "#3",
            response:
              "The employee rates Spyne.ai at 3 for recommendation due to work-life balance concerns caused by late-night calls. Suggest addressing this issue...",
          },
        ],
      },
  ],
};
export const ANALYTICS_OF_RESPONSES = {
  date: "Aug 24",
  topic:"MANAGER SATISFACTION",
  title: "I received positive response from my manager for my accomplishments.",
  feedback: [
    "Congratulations on being in the top 25%!",
    "You must be doing something awesome. Got any tips or tricks?",
  ],
  monthlyScore: {
    "Strongly Disagree": "0%",
    "Disagree": "0%",
    "Neither Disagree Nor Agree": "0%",
    "Agree": "25%",
    "Strongly Agree": "75%",
  },
  monthlyResult: {
    data: { may: "78", june: "82", july: "50" },
    rating: "13% above",
    score: "100% favourable",
    result:"August Results",
    meanScore: "4.5 mean score"
  },
  recommendation: [
    {
      imgUrl: "https://i.ibb.co/mtGbdHr/dummy2.png",
      name: "Hughes Systique",
      designation: "Senior Manager",
      submittedDate: "Submitted: April 12, 2022",
      message:
        "Everyone craves for appreciation of their work. It’s a human nature. Try celebrating smaller wins by sending an appreciation email including your leadership. Also do appreciate people’s good work during sprint retro’s.",
    },
    {
      imgUrl: "https://i.ibb.co/BtQJPNc/dummy3.png",
      name: "Sopra Technolgies",
      designation: "Senior Manager",
      submittedDate: "Submitted: May 12, 2022",
      message:
        "Doing more of spot awards have helped me a lot in inculcating a culture of appreciation. Every sprint we announce a sprint champion and give a small spot award.",
    },
    {
      imgUrl: "https://i.ibb.co/mtGbdHr/dummy1.png",
      name: "Hughes Systique",
      designation: "Senior Manager",
      submittedDate: "Submitted: June 12, 2022",
      message:
        "Use tools like accolades which gamifies the overall appreciation chain helped me a lot. We saw a drastic increase in our team’s productivity when we started regular appreciation using accolades.",
    },
  ],
};
export const THEMES = [
  {
    title: "Employee Engagement",
    tag: "Opportunity",
    analytics: {
      verdict: "59% favorable",
      data: { may: "78", june: "82", july: "50" },
    },
    subThemes: ["Lack of 1:1’s", "Appreciation"],
  },
  {
    title: "Manager Satisfaction",
    tag: "Opportunity",
    analytics: {
      verdict: "45% favorable",
      data: { june: "82", july: "50", august: "90" },
    },
    subThemes: ["Work Life Balance", "Process Efficiency"],
  },
  {
    title: "Career Development",
    tag: "Opportunity",
    analytics: {
      verdict: "35% favorable",
      data: { jan: "58", feb: "62", march: "55" },
    },
    subThemes: ["Trainings", "Promotions"],
  },
  {
    title: "Leaders connect and org Vision",
    tag: "Strength",
    analytics: {
      verdict: "80% favorable",
      data: { may: "88", june: "72", july: "80" },
    },
    subThemes: ["Vision Disconnect", "Leaders connect"],
  },
  {
    title: "Deliver results",
    tag: "Opportunity",
    analytics: {
      verdict: "61% favorable",
      data: { may: "78", june: "82", july: "50" },
    },
    subThemes: ["Tech Debt", "Processes"],
  },
  {
    title: "Attrition",
    tag: "Opportunity",
    analytics: {
      verdict: "60% favorable",
      data: { may: "28", june: "42", july: "50" },
    },
    subThemes: ["Work Life Balance", "Pay"],
  },
];
